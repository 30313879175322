





  import { Component, Prop, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { priceFormatter } from '~/utils/filters'

  const products = namespace('products')
  const layout = namespace('layout')
  const stocks = namespace('stocks')

  @Component
  export default class OneCmsProductFetcherComponent extends Vue {
    name: string = 'OneCmsProductFetcherComponent.vue'
    err: any = null
    isLoading: boolean = false

    @products.Action fetchProductWithPriceAndStock: any;
    @products.Getter getProduct: any;
    @products.Getter getProductPrice: any;
    @products.Getter getProductImages: any;
    @layout.Getter isGrossSelected: any
    @layout.Getter getCurrency: any
    @stocks.Getter getProductStocks: any

    @Prop({
      required: true,
      type: String,
    })
    readonly id!: string

    @Prop({
      required: false,
      default: 'div',
      type: String,
    })
    readonly tag!: string

    @Prop({
      required: false,
      type: String,
    })
    readonly classes!: string

    get product() {
      // TODO: Niestety getter nie został otypowany wiec musi byc any - https://jira.unity.pl/browse/UNITYONE-6274
      const productPrice: any | null = this.getProductPrice(this.id, this.isGrossSelected)
      const productObject = this.getProduct(this.id)
      if (!productObject) {
        // We want to return null here -> user in HTML editor should check if product is already loaded
        return null
      }
      return {
        ...productObject,
        price: {
          ...(productPrice !== null ? productPrice : {}),
          formattedCurrentPrice(): string | null {
            if (!productPrice) {
              return null
            }
            return priceFormatter(productPrice.price, this.getCurrency) || null
          },
          formattedCatalogPrice(): string | null {
            if (!productPrice) {
              return null
            }
            return priceFormatter(productPrice.catalogPrice, this.getCurrency) || null
          },
          isLoading: this.$wait.is(`product-${this.id}-price-loading`),
        },
        getProductImage: this.getProductImages,
        stocks: {
          ...this.getProductStocks(this.id),
          isLoading: this.$wait.is(`product-${this.id}-stocks-loading`),
        },
      }
    }

    mounted() {
      if (this.id) {
        this.isLoading = true
        this.fetchProductWithPriceAndStock(this.id)
          .catch((e: any) => {
            this.$logger.warn(e)
            this.err = e
          })
          .finally(() => { this.isLoading = false })
      }
    }
  }
